@import "src/styles/common";

.font-title {
  color: $text;
  font-family: Inter-Bold, serif;
  font-style: normal;
  margin: 0;

  &_h1 {
    font-size: 32px;
    line-height: 39px;
  }

  &_h2 {
    font-size: 24px;
    line-height: 22px;
  }

  &_h3 {
    font-size: 18px;
    line-height: 22px;
  }
}