@import '../../../styles/common.scss';

.item-catalog-container {
  display: flex;
  padding-top: 32px;
}
.item-catalog-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.item-catalog-title {
  cursor: pointer;
  font-family: Inter-Regular, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: $main-color;
  margin: 0;
}
.date-catalog {
  font-family: Inter-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $text;
  padding-top: 12px;
  padding-bottom: 12px;
}
.desc-catalog {
  font-family: Inter-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $text;
}
.favourite-svg {
  position: absolute;
  top: 35px;
  svg {
    width: 24px;
    height: 24px;
  }
}
.svg-container {
  display: flex;
  padding-right: 100px;
  position: relative;
  :hover {
    cursor: pointer;
  }
}

.load-more-label {
  color: $main-color;
  margin-left: 5px;
  font-family: Inter-Regular, sans-serif;
  cursor: pointer;
}