@import '../../styles/common.scss';

.workzone {
    padding: 40px 68px 40px 148px;

    background: $second-background;
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    .workzone-content {
        margin-top: 18px;
        display: flex;

        .workzone-content-right {
            flex: 1;
        }
        .workzone-content-left {
            flex: 0 0 392px;
            margin-top: 22px;
            margin-left: 40px;
        }
    }
}
