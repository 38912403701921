@import '../../styles/common.scss';

.container-table-statistic {
  background:$white;
  border: 1px solid $border;
  border-radius: 6px
}
.table-class-statistic {
  margin-left: 20px;
  margin-top: 38px;
  width: 20%;
}
.headerClass-statistic {
  background: $background-color;
  color: $header-background;  
}
.container-page-list-renderer-statistic {
  background: $white;
  padding: 0px;
  margin-left: 40px;
}
#pageDropDown {
  display: none;
}
.btnCustom-statistic {
  background-color: $white;
  font-family: Inter-Regular, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.pagination {
  --bs-pagination-border-color: none;
  --bs-pagination-hover-bg: none;
  --bs-pagination-focus-bg: none;
  --bs-pagination-border-radius: 2px;
  --bs-pagination-focus-box-shadow: 0 0 0 0.1rem rgba(18, 46, 194, 1);
}
.page-link {
  font-family: Inter-Regular, serif;
  font-size: 16px;
  line-height: 24px;
  width: 32px;
  height: 32px;
  color: $text;
}
.row {
  width: 0;
  margin-left: 20px;
  margin-top: 44px;
}
