@import '../../styles/common.scss';

.base-checkbox-container {
    display: flex;
    align-items: center;
    .input-checkbox {
        display: none;
    }
    .base-checkbox-icon-box {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
        border: 2px solid $text-secondary;
        border-radius: 2px;
        transition-property: background-color, border-color;
        transition: 0.12s ease-in;

        .base-checkbox-icon {
            width: 100%;
            height: 100%;
            color: $white;
            cursor: pointer;
        }

        &-checked {
            background-color: $main-color;
            border-color: $main-color;
        }
    }

    .base-checkbox-label {
        font-size: 16px;
        line-height: 20px;
    }
}

    .base-checkbox-label {
        font-size: 16px;
        line-height: 20px;
    }
