@import "src/styles/common";

.speaker {
    position: relative;
    padding: 18px 18px 18px 48px;
    min-height: 100px;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 50px;

    .speaker-text {
        flex: 1;

        font-size: 24px;
        line-height: 24px;

        .speaker-text-keyword {
            font-family: Inter-Bold, sans-serif;
            color: $main-color
        }
    }

    .speaker-stop-button {
        align-self: flex-start;
        flex-shrink: 0;
        flex-grow: 0;

        padding: 0;
        margin-left: 24px;

        min-width: auto;
        min-height: auto;
        height: 64px;
        width: 32px;

        > .button-content {
            gap: 0
        }
    }

    .speaker-timing {
        display: flex;
        align-items: center;
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 4px;
        font: 12px/16px Inter-Reqular, sans-serif;

        > div {
            color: $text-primary;
            margin-left: 12px;
        }
    }
    .phrase-input {
        margin-bottom: 0px !important;
        
        > div {
            background-color: white;
            border: none;
        }
    }
}
