@import '../../styles/common.scss';

.container-advantage {
  background-color: $white;
  padding: 48px 115px 48px 115px;
  display: flex;
  justify-content: center;

  .container-cards   {
    display: flex;
    justify-content: space-between;   
    max-width: 1224px;
    width: 100%;
  }
}