@import "src/styles/common";

.select-image-modal {
  min-width: 800px;

  .files-wrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 350px 350px;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 20px;
    max-height: 600px;

    .image {
      width: 350px;
      height: auto;
      box-shadow: 0 0 4px rgba($color: $black, $alpha: 0.5);
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 10px rgba($color: $black, $alpha: 0.5);
      }
    }

    .image-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      align-content: space-between;
    }

    .original-file-name {
      font-family: 'Inter-Regular', serif;
      color: $text-primary;
      max-width: 350px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
