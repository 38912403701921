@import '../../styles/common.scss';

.wysiwyg-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  &-toolbar {
    padding: 0;
    border: none;
    margin: 0 -4px;

    .rdw-inline-wrapper,
    .rdw-fontsize-wrapper,
    .rdw-colorpicker-wrapper,
    .rdw-link-wrapper,
    .rdw-remove-wrapper,
    .rdw-image-wrapper,
    .rdw-list-wrapper,
    .rdw-text-align-wrapper,
    .rdw-history-wrapper {
      margin-bottom: 8px;
    }

    .rdw-option-wrapper {
      box-sizing: border-box;
      padding: 0;
      width: 30px;
      height: 30px;
      border: none;
      transition-property: background-color;
      transition: 0.16s ease-in-out;
      box-shadow: none;
      border-radius: 4px;

      &:hover, &.rdw-option-active {
        box-shadow: none;
        border: none;
        background-color: $background-color;
      }
    }
    .rdw-dropdown-selectedtext {
      width: 50px;
      border: none;
      box-sizing: border-box;
    }

    .rdw-dropdown-wrapper {
      border: none;
      transition-property: background-color;
      transition: 0.16s ease-in-out;
      box-shadow: none;
      border-radius: 4px;

      &:hover, &.rdw-option-active {
        box-shadow: none;
        border: none;
        background-color: $background-color;
      }

      .rdw-dropdown-selectedtext {
        padding: 0 5px 0;

        > span {
          flex: 1;
          text-align: center;
          margin-right: 4px;
        }
      }

      .rdw-dropdown-carettoopen,
      .rdw-dropdown-carettoclose {
        position: initial;
      }

      .rdw-dropdown-carettoopen {
        transition: transform 0.24s ease-in-out;
        transform: rotate(0);
      }
      .rdw-dropdown-carettoclose {
        transition: transform 0.24s ease-in-out;
        transform: rotate(180deg);
        border-top: 6px solid black;
        border-bottom: none;
      }
    }
  }

  &-editor {
    contain: strict;
    flex: 1;
    border: 1px solid $border-color;
    border-radius: 4px;
    background-color: $background-color;
    display: flex;
    position: relative;
    overflow: hidden;
    font: 16px/18px Inter-Regular, sans-serif;

    .DraftEditor-root {
      overflow: auto;
      box-sizing: border-box;
      padding: 0 12px;
      height: initial;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    figure {
      margin: 0;
    }
  }

  .rdw-colorpicker-modal {
    width: 180px;
    height: 180px;
    left: -4px;
  }

  .rdw-colorpicker-modal-options {
    overflow-y: auto;
    overflow-x: hidden;
    margin: 4px 0 0;
  }

  .public-DraftEditorPlaceholder-root {
    top: 12px;
    left: 18px;

    .public-DraftEditorPlaceholder-inner {
      transition: color 0.12s ease-in;
    }
  }
}
