$header-height: 100px;
$footer-height: 125px;

$sidebar-width: 80px;

$main-color: #122EC2;
$border-color: #e4e4e4;
$background-color: #F8F8FC;
$second-background: #F5F5F5;
$tag-background-color: #7985C3;
$header-background: #0C1960;

$text-primary: #262626;
$text-secondary: #7D7D7D;
$text: #323232;
$white: #ffffff;
$black: #000000;
$grey-middle: #D9D9D9;
$error: #ed4337;
$border: #E4E4E4;

@mixin content {
  padding: 40px 30px 36px 28px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@mixin bordered-content {
  background-color: $white;
  border: 1px solid $border;
  border-radius: 6px;
}
