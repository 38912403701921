@import '../../../styles/common.scss';

.add-item-node {
  margin-top: 12px;

  .add-item-node-input {
    display: none;
    align-items: center;
    margin-bottom: 8px;
    color: #2029D4;

    > input {
      width: 220px;
      margin-right: 18px;
      border: 1px solid $border;
      border-radius: 4px;
      padding: 0 12px;
      height: 28px;
      font: 14px/24px 'Inter-Regular', serif;

      &:focus, &:active, &:focus-visible {
        border: 1px solid $border;
        outline: none;
      }

      &::placeholder {
        color: $text-secondary;
      }
    }

    > .icon-wrapper {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      cursor: pointer;
    }

    &__show {
      display: flex;
    }
  }

  .add-item-node-button {
    font-family: 'Inter-Regular', serif;
    display: flex;
    column-gap: 9px;
    color: #2029D4;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    user-select: none;

    &__hide {
      display: none;
    }
  }
}
