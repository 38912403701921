@import "src/styles/common";

.document-details {
  min-width: 90%;
  font-family: Inter-Regular, serif;
  color: $text;

  .sticker-list {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 28px;
  }

  .sticker {
    padding: 4px 12px;
    border: 1px solid $main-color;
    border-radius: 6px;
    color: $main-color;
    font-size: 16px;
    line-height: 24px;
  }
}