@import "src/styles/common";

.upload-modal {
  width: 560px;

  .upload-label {
    color: $main-color;
    font-family: 'Inter-Regular', serif;
    cursor: pointer;
  }

  .file-wrapper {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }

  .delete-icon {
    color: $error;
    cursor: pointer;
  }

  .image-file {
    box-shadow: 0 0 4px rgba($color: $black, $alpha: 0.5);
  }

  .file-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 250px;
    flex: 1;
  }

  .files-list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 10px;
    max-height: 300px;
    overflow: auto;
    padding: 10px;
    border: 1px solid $border;
  }
}