@import '../../styles/common.scss';

.error-message {
  font-family: Inter-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $error;
}

.login-form-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  &-right {
    display: flex;
    align-items: center;

    & > .button {
      margin-right: 32px;
    }
  }

  .login-form-forgot-pass-btn {
    padding: 0;
    white-space: nowrap;
  }
}
