@import './styles/common.scss';
@import './fonts/font.scss';

html {
    font-family: Inter-Regular, sans-serif;
    font-size: 14px;
    line-height: 1.25;
}

body {
    margin: 0;
}

body * {
    box-sizing: border-box;
}

.App {
    min-height: 100vh;
    background: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.auth-page-content {
    box-sizing: border-box;
    padding-left: 80px;
    width: 100%;
}

/*
.button {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: white;
    border-width: 2px;
    border-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        box-shadow: 1px 1px 15px black, -1px -1px 15px black;
    }
}*/
