@import "src/styles/common";

.add-subscription-modal {
  min-width: 650px;
  max-height: 650px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .subscription-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .subscription-fields {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .row-element{
      width: 50%;
    }
    .padding-last {
      padding-bottom: 35px;
    }
  }
}