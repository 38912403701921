@import "src/styles/common";

.catalog-articles {
  @include bordered-content;

  padding: 40px 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &-list {
    overflow: auto;
    row-gap: 32px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &-pagination {
    margin-top: auto;
    padding-top: 32px;
  }
}