@import "src/styles/common";

.reports {
  @include content;
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    align-items: center;
    column-gap: 179px;
    margin-bottom: 34px;
  }
}
