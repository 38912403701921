@import "src/styles/common";

.external-sources {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 500px;
}

.source-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow: auto;

  .source-link {
    display: flex;
    column-gap: 26px;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    color: $main-color;
    font-family: Inter-Regular, serif;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
  }
}
