@import '../../../styles/common.scss';

.container-content {
  display: flex;
  flex-direction: column;
  max-width: 240px;
  width: 100%;
  align-items: center;
  text-align: center;
  white-space: pre-line;
  .content {
    font-family: Inter-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .advantage-title {
    font-family: Inter-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
}