@import "src/styles/common";

.popup-message {
  min-width: 400px;
  min-height: 100px;
  background-color: $background-color;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 200;
  border-radius: 5px;
  padding: 10px 15px;
  border: 1px solid $text-secondary;

  &_title {
    font-family: 'Inter-SemiBold', serif;
    margin-bottom: 15px;
    border-bottom: 1px solid $border;
    padding-bottom: 5px ;
  }

  &_text {
    font-family: 'Inter-Regular', serif;
    font-size: 16px;
  }
}