@font-face {
    font-family: 'Inter-Medium';
    src: url('./Inter-Medium.woff2') format('woff2')
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('./Inter-Bold.woff2') format('woff2')
}

@font-face {
    font-family: 'Inter-Regular';
    src: url('./Inter-Regular.woff2') format('woff2')
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('./Inter-SemiBold.woff2') format('woff2')
}