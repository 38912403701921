@import "src/styles/common";

.catalog-tree-workzone {
  display: flex;
  flex-direction: column;

  .catalog-tree-header {
    height: 60px;
    width: 100%;
    background: $header-background;
    margin-bottom: 20px;
    border-radius: 6px;
    padding-left: 24px;
    display: flex;
    align-items: center;
  }
  .catalog-tree-header-label {
    color: $white;
    font-size: 24px;
    line-height: 24px;
    font-family: Inter-Bold, sans-serif;
  }
  .catalog-tree-data-container {
    display: flex;
    flex-direction: column;
  }
}
