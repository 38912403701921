@import "src/styles/common";

.search-results-container {
  width: 100%;
  margin-bottom: 32px;
}

.labels-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.category-label {
  height: 42px;
  margin-right: 18px;
  margin-bottom: 18px;
  padding: 0 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  font: 16px/24px Inter-Regular, serif;
  color: $white;

  background: $main-color;
  border-radius: 21px;

  .category-label-button {
    padding: 0;
    margin: 0 0 0 8px;
    color: $white;
    min-width: auto;
    min-height: auto;

    > .button-content {
      gap: 0;
    }
  }

  .category-label-icon {
    > svg {
      width: 16px;
      height: 16px;
    }
  }
}

.search-results-founding {
  color: $text;
  font: 16px/24px Inter-Regular, sans-serif;
}
