@import '../../styles/common.scss';

.recovery-password {
  &-description {
    margin-bottom: 24px;
    font-size: 16px;
  }

  &-success-msg {
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  &-submit-button {
    margin-top: 32px;
  }
}
