@import '../../styles/common.scss';

.profile {
  @include content;
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    align-items: center;
    column-gap: 179px;
    margin-bottom: 34px;
  }
}
.content-container {
  background: $white;
  max-height: 455px;
  height: 100%;
  border: 1px solid #E4E4E4;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  padding-left: 32px;
  padding-bottom: 32px;
  gap: 96px;
}

.profile-info {
  span {
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  }
  p {
    font-family: Inter-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
.profile-select {
  max-width: 394px;
}
.btn-update {
  max-width: 394px;
  width: 100%;
  .button {
    width: 100%;
    background: $white;
    border: 2px solid $main-color;
    color: $main-color;
    &:hover {
      background-color: $main-color;
      color: $white;
  }
  }
}
