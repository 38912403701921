@import '../../../styles/common.scss';
@import '../../../fonts/font.scss';

.auth-header {
    width: 80px;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    background: $header-background;
}

.auth-header-logo-wrapper {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-header-logo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    text-align: center;

    font-size: 8px;
    font-family: Inter-Bold, sans-serif;
    color: $header-background;
}

.auth-header-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px 0 16px;
}

.auth-header-body-links {
    flex: 1;
}

.auth-header-button {
    cursor: pointer;
    height: 88px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    text-decoration: none;

    & > span {
        font-family: Inter-Regular, sans-serif;
        font-size: 12px;
        line-height: 24px;
        white-space: nowrap;
    }

    transition: background-color 0.165s ease-in;

    &:hover, &.active {
        background-color: $main-color;
    }
}

.auth-header-logout {
    border: none;
    background-color: $header-background
}
