@import '../../../styles/common.scss';
.tree-item {
  &-label-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    .tree-item-label {
      font-weight: 400;
      line-height: 22px;
      font-size: 18px;
      font-family: Inter-Medium;
      color: $text;
      margin-right: 11px;
      &.choosen-label {
        font-weight: 600;
        font-family: Inter-SemiBold;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .tree-item-children {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
  }
  .tree-item-show-more {
    font-family: Inter-Regular;
    margin-left: 48px;
    color: $main-color;
    margin-top: 20px;
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
}