@import '../../styles/common.scss';

.title {
    font-family: Inter-Bold;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    &.header-style {
        font-size: 32px;
        color: $main-color;
        line-height: 39px;
        cursor: pointer;
    }
    &.footer-style {
        color: $white;
        font-size: 18px;
        line-height: 22px;
        opacity: 0.6;
    }
}