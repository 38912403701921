@import '../../styles/common.scss';

.footer {
    padding-top: 21px;
    padding-left: 108px;
    padding-right: 108px;
    background: $header-background;
    height: $footer-height;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-footer-block {
        display: flex;
        flex-direction: row;
        .title-block {
            display: flex;
            flex-direction: column;
            .year-info {
                font-family: Inter-Regular;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: $white;
            }
        }
    }

    .right-footer-block {
        display: flex;
        flex-direction: row;
        .info-block {
            margin-left: 60px;
            display: flex;
            flex-direction: column;
            div {
              margin-bottom: 11px;  
            }
        }
    }
}