@import 'src/styles/common';

.internet-resource {
  @include bordered-content;
  max-width: 1302px;

  padding: 20px 17px 41px;

  &-table {
    max-width: 1132px;
  }

  .add-resource-button {
    margin-bottom: 35px;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7px;
    color: $main-color;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}