@import "src/styles/common";

.doc-link {
  display: inline-flex;
  align-items: center;
  column-gap: 5px;
  text-decoration: none;
  margin: 0 3px -4px;
  color: $main-color;

  &:hover {
    color: $text;
  }

  .link-text {
    text-decoration: underline white;
    align-self: flex-end;

  }

  .link-icon {
    display: flex;
    color: $main-color;
  }
}