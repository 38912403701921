@import '../../../../styles/common.scss';

.support-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
        margin-right: 16px;
    }
    .support-item-text {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      font-family: Inter-Regular;
      color: $main-color;
      opacity: 0.8;
      text-decoration: none;
    }
}