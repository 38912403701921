@import '../../styles/common.scss';

.header {
    height: $header-height;
    background: $white;
    padding-left: 108px;
    padding-right: 108px;
    border-bottom: 1px solid $border;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;

    .button-container {
        justify-content: center;
        align-items: center;
        display: flex;

        > .button {
            margin-left: 16px;
        }
    }
}