@import '../../styles/common.scss';

.catalog-results {
  padding-top: 18px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.back-to-search {
  text-transform: none;
  padding: 0;
  span {
    color: $main-color;
    font-family: Inter-Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
}

.header-resultCatalog {
  button[type=button]:hover{
    text-decoration: none;
}
h1 {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 22px;
  margin: 0;
  padding-top: 32px;
  padding-bottom: 14px;
 }
}

.parents-title {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $text-secondary;
}
