@import "src/styles/common";

.pagination {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 8px;
  margin: 0;
  padding-left: 0;

  .page-item {
    font-family: Inter-Regular, serif;
    font-size: 16px;
    line-height: 24px;
    width: 32px;
    height: 32px;

    &--active {
      border: 1px solid #122EC2;
      border-radius: 2px;
    }
  }

  .page-item-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text;
    cursor: pointer;
  }

  .previous, .next {
    cursor: pointer;

    &.disabled {
      svg {
        path {
          stroke: #C9C9C9;
        }
      }
    }
  }
}