@import "src/styles/common";

.keyword-form {
  max-height: 650px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .keyword-info {
    margin-bottom: 16px;
  }

  .synonyms-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .add-synonym-button {
    margin-left: -24px;
    align-self: flex-start;
  }

  .delete-synonym-icon {
    color: $main-color;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .synonym-wrapper {
    display: flex;
    column-gap: 10px;
  }
}