@import "src/styles/common";

.tab-item {
  font-size: 18px;
  line-height: 22px;
  font-family: 'Inter-SemiBold', serif;
  font-weight: 700;
  position: relative;
  cursor: pointer;
  padding-bottom: 9px;

  &--active {
    color: $main-color;
  }

  .active-underline {
    width: 100%;
    display: block;
    background: $main-color;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}