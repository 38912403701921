@import '../../styles/common.scss';

.article-modal {
    display: flex;
    flex-direction: column;
    width: 75%;

    .article-form {
        flex: 1;
        display: flex;
        flex-direction: column;

        .article-form-inputs-block {
            margin-bottom: 16px;
        }

        .images-controls-wrapper {
            display: flex;
            column-gap: 20px;
            margin-bottom: 10px;
        }

        .article-form-editor-block {
            flex: 1;
            margin-bottom: 32px;
        }

        .article-form-buttons-block {
            display: flex;
            > .button {
                margin-right: 24px;
            }
        }
    }
}
