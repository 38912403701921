@import "src/styles/common";

.speaker-button-wrapper {
    margin-left: 24px;

    width: 64px;
    height: 64px;

    align-self: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba($main-color, 0.2);
    border-radius: 50%;
}

.speaker-button {
    width: 56px;
    height: 56px;

    background: $main-color;
    border: none;
    border-radius: 50%;

    cursor: pointer;

    transition-property: box-shadow;
    transition: 0.16s ease-in-out;

    &:hover {
        box-shadow: 1px 1px 6px 2px $main-color, -1px -1px 6px 2px $main-color;
    }
}
