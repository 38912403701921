@import "src/styles/common";

.editable-item {
  padding: 6px 12px;
  background: $main-color;
  border-radius: 100px;
  color: $white;
  font-family: Inter-Regular, serif;
  display: flex;
  align-items: center;

  &-delete, &-edit {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    cursor: pointer;
  }
}