@import "src/styles/common";

.add-resource-modal {
  min-width: 650px;
  max-height: 650px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .resources-form {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .resources-fields {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .parameter-wrapper {
    display: flex;
    column-gap: 10px;

    > * {
      flex-grow: 1;
    }
  }

  .parameters-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 15px;
  }

  .add-parameter-button {
    align-self: flex-start;
  }

  .delete-parameter-icon {
    color: $main-color;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .parameters-error {
    color: $error;
    font-family: Inter-Regular, serif;
  }
}