@import "src/styles/common";

.catalog-tree {
  @include bordered-content;

  padding: 40px 30px;
  background-color: white;
  border: 1px solid $border;
  border-radius: 6px;
  overflow-y: auto;

  &-title {
    margin-bottom: 20px;
  }
}
