@import '../../styles/common.scss';

.container-table {
  background:white;
  border: 1px solid $border-color;
  border-radius: 6px;
}
.tableClass {
  margin-left: 20px;
  width: 98%;
}
.users-table-rules-icon {
  width: 40px;
  height: 20px;
  &:hover {
    cursor: pointer;
    path {
      color: $main-color;
    }
  }
}
.headerClass {
  background: $background-color;
  color: $header-background;
}
.container-page-list-renderer {
 // background: $second-background;
  display: flex;
  padding-left: 45px;
  padding-top: 38px;
  margin-top: 20px;
}
#pageDropDown {
  display: none;
}
.btnCustom{
 // background-color: $second-background;
  font-family: Inter-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: none;
}
.check-box-groups {
  display: flex;
  padding-bottom: 30px;
  padding-left: 20px;
}
.check-box-groups> *{
  padding-right: 20px;
}
.search-group{
  display: flex;
  padding-top: 24px;
  padding-left: 15px;
    .base-input-container {
      border: solid $border 1px;
      width: 224px;
    }
    .base-select-container {
      border: solid $border 1px;
      width: 224px;
    }
}
.search-group> *{
  padding-right: 20px;
}
