@import '../../../../styles/common.scss';

.subscription-history-card-in-modal {
        display: flex;
        flex-direction:column;
        border: 1px solid $main-color;
        justify-content: space-between;
        border-radius: 4px;
        padding: 20px;
        // height: 300px;
        position: relative;
        width: 400px;
        .is-active-label {
            position: absolute;
            background: $main-color;
            color: $background-color;
            top: 10px;
            right: 10px;
            padding: 5px;
            border-radius: 4px;
            font-family: Inter-SemiBold, serif;
        }
        .sub-name {
            font-family: Inter-Bold, serif;
            font-size: 22px;
        }
        > span {
            font-size: 14px;
            font-family: Inter-Regular, serif;
        }
}