.tree {
  display: flex;
  flex-direction: column;
}

.keyword-form-buttons-block {
  margin-top: 32px;
  display: flex;

  > .button {
    margin-right: 24px;

    &.keyword-form-buttons-block-delete {
      color: red;
      padding: 0;
    }
  }
}

.tree-node-deleting-modal-text {
  font: 16px/24px Inter-Regular, sans-serif;
}

.tree-node-deleting-modal-buttons {
  display: flex;
  margin-top: 32px;

  > .button {
    margin-right: 24px;
  }
}
