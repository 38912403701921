@import '../../styles/common.scss';

.button {
    padding: 10px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 42px;
    min-width: 95px;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    white-space: nowrap;

    &-content {
        display: flex;
        column-gap: 9px;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &.primary {
        background: $main-color;
        border: none;
        color: $white;
        &:hover {
            padding: 8px 22px;
            background: $white;
            border: 2px solid $grey-middle;
            color: $text-primary;
        }
    }
    &.secondary {
        background: $white;
        border: 2px solid $grey-middle;
        color: $text-primary;
        padding-top: 8px;
        padding-bottom: 8px;
        &:hover {
            background-color: $grey-middle;
        }
    }
    &.border-off {
        color: $text-secondary;
        border: none;
        background-color: initial;

        &:hover {
            text-decoration: underline;
        }
    }
    &.border-off-primary {
        color: $main-color;
        border: none;
        background-color: initial;

        &:hover {
            text-decoration: underline;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &:disabled, &:disabled:hover {
        padding: 10px 24px;
        cursor: default;
        background: $grey-middle;
        border: none;
        color: $text-secondary;
    }
}
