.modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
}

.modal-base {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*top: 0;
    left: 0;
    right: 0;
    bottom:0;
    margin: auto;*/
    width: 456px;
    box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.5);
    border-radius: 4px;
    background: rgb(255, 255, 255);
    overflow: auto;
    outline: none;
    padding: 32px;

    .title {
        margin: 0 0 32px;
        font-family: Inter-Bold, sans-serif;
        font-size: 24px;
        line-height: 24px;
        text-align: left;
    }

    .close-icon-button {
        position: absolute;
        top: 24px;
        right: 22px;
        border: none;
        background: transparent;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }
}

.right-side-modal {
    top: 0;
    bottom: 0;
    right: 0;
    left: initial;
    border-radius: 4px 0 0 4px;
    transform: translate(0, 0);
    width: max-content;
}
