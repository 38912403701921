@import '../../styles/common.scss';

.parent-node-selector-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 56px;

  .parent-node-selector-value-box {
    box-sizing: border-box;
    flex: 1;
    margin-right: 16px;
    border-radius: 4px;
    background: $background-color;
    padding: 6px 12px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
    border: 1px solid $border-color;

    .parent-node-selector-title {
      position: absolute;
      font: 16px/24px Inter-Regular, sans-serif;
      top: 6px;
      transform: translate(9px, 9px);
      color: $text-secondary;
      transition: 0.15s;
      &.with-value {
        font: 10px/18px Inter-Regular, sans-serif;
        transform: none;
      }
      &.error {
        color: red;
      }
    }
    .parent-node-selector-value {
      font: 16px/24px Inter-Regular, sans-serif;
    }
  }

  .parent-node-selector-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-color;
    border: 1px solid $border-color;
    height: 56px;
    width: 56px;
    border-radius: 4px;
    cursor: pointer;
    padding: 0;
    > svg {
      width: 24px;
      height: 24px;

      color: $main-color;
    }
  }
}

.parent-node-selector-modal-search {
  margin-bottom: 24px;
}

.parent-node-selector-modal-buttons {
  display: flex;
  margin-top: 42px;

  > .button {
    margin-right: 34px;
  }
}
