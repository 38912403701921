@import "src/styles/common";

.database {
  @include content;

  &-header {
    display: flex;
    align-items: center;
    column-gap: 179px;
    margin-bottom: 34px;

    .search-wrapper {
      flex: 1;
      background-color: $white;

      .icon {
        top: 12px;
      }
    }

    .search {
      min-height: 42px;
    }
  }

  &-content {
    flex: 1;
    display: flex;
    column-gap: 28px;
    overflow-y: auto;
  }

  .database-catalog-tree {
    flex: 1;
    max-width: 465px;
  }

  .database-catalog-articles {
    flex: 1;
  }
}
