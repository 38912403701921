@import "src/styles/common";

.other-options {
  display: flex;
  align-items: flex-start;
  column-gap: 28px;
  overflow: hidden;

  .form-wrapper {
    @include bordered-content;
    padding: 32px 32px 28px 32px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 0 1 50%;
  }

  &-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    font: 22px/24px Inter-Regular, sans-serif;
    color: $text-secondary;
    min-height: 400px;
  }

  .section {
    margin-bottom: 32px;

    &-working-with-articles {
      margin-bottom: 16px;
    }
  }

  .currency {
    @include bordered-content;
    padding: 26px 29px 26px 16px;
    flex: 0 1 50%;

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .support-field {
    max-width: 394px;
  }

  .article-input-wrapper {
    display: flex;
    column-gap: 12px;
  }

  .article-input {
    max-width: 60px;
    padding-left: 0;
  }

  .add-button {
    color: $main-color;
    padding: 0;
    min-height: auto;
    margin-top: 12px;
  }

  .items-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .currency-title {
    margin-bottom: 25px;
  }

  .currency-table {
    max-width: 640px;
  }
}

.other-options-edit-modal {
  .other-options-edit-modal-input {
    margin-bottom: 32px;

    .input-title {
      text-transform: capitalize;
    }
  }
}
