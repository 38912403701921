@import '../../styles/common.scss';

.base-select-container {
    box-sizing: border-box;
    position: relative;
    height: 56px;
    padding: 6px 12px;
    display: flex;
    align-items: flex-end;
    background: $background-color;
    border-radius: 4px;
    border: 1px solid $border-color;
    cursor: pointer;
    transition-property: border-color;
    transition: 0.14s ease-in-out;

    &.with-error {
        border: 1px solid $error;
    }

    .base-select {
        flex: 1;
        overflow: hidden;

        &-title {
            cursor: pointer;
            position: absolute;
            top: 6px;
            font: 16px/24px Inter-Regular, sans-serif;
            color: $text-secondary;
            transform: translate(9px, 9px);
            transition: 0.15s;
            &.focused {
                font: 10px/18px Inter-Regular, sans-serif;
                transform: none;
            }
            &.error {
                color: red;
            }
        }

        &-selected-list {
            display: flex;
            align-items: center;

            &-item {
                display: flex;
                box-sizing: border-box;
                background-color: $tag-background-color;
                color: $white;
                padding: 2px 8px;
                height: 20px;
                border-radius: 10px;
                font-size: 12px;
                line-height: 16px;
                cursor: default;

                &:not(:last-child) {
                    margin-right: 12px;
                }

                > span {
                    display: flex;
                    align-items: center;
                }

                &-remove {
                    cursor: pointer;
                    margin-left: 8px;
                    height: 14px;
                    width: 14px;
                }
            }
        }

        &-list {
            position: absolute;
            top: 100%;
            left: 0;
            transform: translateY(4px);
            z-index: 100;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
            width: 100%;
            padding: 6px 0;
            border-radius: 4px;
            border: 1px solid $border-color;
            background-color: $background-color;
            display: none;
            &.opened {
                display: block;
            }
            &-item {
                padding: 12px;
                transition: background-color 0.12s ease-in;

                &:hover, &.selected {
                    background-color: $white;
                }

                .base-checkbox-container {
                    cursor: pointer;
                }
            }
        }

        &-arrow {
            display: flex;
            align-self: center;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            transition: transform 0.24s ease-in-out;

            &.focused {
                transform: rotate(180deg);
            }

            .arrow-svg {
                > path {
                    fill: $text-primary;
                }
            }
        }
    }
}
