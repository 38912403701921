@import '../../styles/common.scss';

.base-input-container {
    box-sizing: border-box;
    border-radius: 4px;
    background: $background-color;
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid $border-color;
    transition-property: border-color;
    transition: 0.14s ease-in-out;
    &.with-title {
        min-height: 56px;
    }
    &.with-error {
        border: 1px solid red;
    }
    .input-title {
        pointer-events: all;
        font-family: Inter-Regular, sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: $text-secondary;
        transform: translate(9px, 15px);
        margin-bottom: 0;
        transition: 0.15s;
        &.focused {
            margin-bottom: 6px;
            font-family: Inter-Regular, sans-serif;
            font-size: 10px;
            line-height: 18px;
            transform:  none;
            color: $text-secondary;
        }
        &.error {
            color: red;
        }
    }
    .base-input {
        font-family: Inter-Regular, sans-serif;
        font-size: 16px;
        line-height: 24px;
        background-color: inherit;
        outline: none;
        border:none;
    }
    .eye-svg-button {
        position: absolute;
        top: 16px;
        right: 12px;
        &:hover {
            cursor: pointer;
            path {
                fill: black;
            }
        }
    }

    .icon {
        position: absolute;
        right: 15px;
    }
    .search-svg {
        position: absolute;
        top: 16px;
        right: 12px;
    }
    .input-children {
        pointer-events: all;
        transform: translate(9px, 15px);
        margin-bottom: 0;
        transition: 0.15s;
        &.focused {
    display: none;
        }
    }
}
