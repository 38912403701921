@import '../../styles/common.scss';

.payment-modal {
    width: max-content;
}
.payment-history-modal {
    /*left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: unset;*/
    max-height: 90%;
    width: max-content
}
.payment-card {
    padding: 24px;
    border-radius: 4px;
    border: 1px solid #122EC2;
    min-width: 390px;
    max-height: 325px;
    height: max-content;
    .title {
        font-family: Inter-Bold, serif;
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 32px;
        text-align: left;
    }
    .sub-title {
        font-family: Inter-SemiBold, serif;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .info-title {
        font-family: Inter-Regular, serif;
        font-size: 16px;
        line-height: 22px;
        &.end {
        color: #E00C0C;
        }
    }
    .buttons {
        width: 395px;
    }
}