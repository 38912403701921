@import "src/styles/common";

.article {
  padding-right: 30px;
  max-width: 100%;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-icons {
    display: flex;
    column-gap: 13px;
  }

  &-icon {
    cursor: pointer;
    color: $main-color;
    &:hover {
      path {
        opacity: 0.1;
      }
    }
  }

  &-title {
    font-family: Inter-Medium, serif;
    font-size: 18px;
    line-height: 24px;
    color: $main-color;
    margin: 0 0 12px;
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  &-text {
    font-family: Inter-Regular, serif;
    font-size: 14px;
    line-height: 24px;
    color: $text;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 24px;
    margin: 0;
    white-space: nowrap;
    max-width: 100%;
  }

  &-load-more {
    font-family: 'Inter-Regular', serif;
    color: $main-color;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }
}