@import '../../styles/common.scss';

.user-modal {
    display: flex;
    flex-direction: column;

    .user-content {
        height: 100%;

        display: flex;
    }

    .user-form {
        min-width: 590px;
        display: flex;
        flex-direction: column;

        .user-info-block {
            margin-bottom: 40px;

            .user-info-block-row {
                margin-bottom: 12px;
                > p {
                    margin: 0;
                }
            }
            .user-info-block-title {
                font-size: 10px;
                line-height: 18px;
                margin-bottom: 2px;
                color: $text-secondary;
            }
            .user-info-block-value {
                font-size: 16px;
                line-height: 24px;
            }
            .user-info-block-checkboxes {
                margin-top: 24px;
            }
            .user-info-block-checkbox {
                margin-top: 18px;
            }
        }
        .user-subscription-history-block {
            flex: 1;
            > h3 {
                font: 24px/24px Inter-Bold, sans-serif;
                margin: 0 0 24px 0;
            }
        }
        .user-form-buttons-block {
            display: flex;
            > .button {
                margin-right: 24px;
            }
        }
    }

    .user-current-plan-block {
        flex: 1;

        .user-current-plan {
            width: 390px;
            box-sizing: border-box;
            padding: 24px;
            border: 1px solid $main-color;
            border-radius: 6px;

            > h3 {
                font: 24px/24px Inter-Bold, sans-serif;
                margin: 0 0 32px 0;
            }
            > p {
                margin: 0;
            }
            .user-current-plan-desc {
                font-family: Inter-Medium, sans-serif;
                margin-bottom: 10px;
            }
            .user-current-plan-expires {
                color: #e00c0c;
            }
        }
    }
}
