@import '../../styles/common.scss';

.collapsible{
  max-width: 1224px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-bottom: 16px;
}

.collapsible .collapsible-content {
  padding: 6px;
  background-color: $white;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter-Regular, sans-serif;
  color: $text;
}
.collapsible .collapsible-header {
  background-color:  $white;
  padding: 6px;
  cursor: pointer;
  height: 107px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.collapsible-title {
  font-family: Inter-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.02em;
  padding-left: 30px;
}
.collapsible-icon {
  color: $main-color;
}