@import '../../../../styles/common.scss';

.container-table-recognize {
  background:$white;
  border: 1px solid $border;
  border-radius: 6px
}
.table-class-recognize {
  margin-left: 20px;
  margin-top: 38px;
}
.headerClass-recognize {
  background: $background-color;
  color: $header-background;  
}
.container-page-list-renderer-recognize {
  background: $white;
  padding: 0px;
  margin-left: 40px;
}
