@import '../../../../styles/common.scss';

.subscription-card-in-modal {
        display: flex;
        flex-direction:column;
        border: 1px solid $main-color;
        justify-content: space-between;
        border-radius: 4px;
        padding: 20px;
        // height: 300px;
        width: 200px;
        .sub-name {
            font-family: Inter-Bold, serif;
            font-size: 22px;
        }
        > span {
            font-size: 14px;
            font-family: Inter-Regular, serif;
        }
}