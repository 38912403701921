@import '../../../styles/common.scss';

.support-container {
margin-top: 25px;
background-color: $white;
padding-top: 40px;
padding-left: 106px;
padding-bottom: 43px;
}

.support-content {
  h1 {
    font-family: Inter-Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }
}
.support-contacts {
  display: flex;
  justify-content: space-between;
  max-width: 25%;
  padding-top: 40px;
}