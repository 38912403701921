@import '../../styles/common.scss';

  .users-page  {
    @include content;
    display: flex;
    flex-direction: column;
  
    &-header {
      display: flex;
      align-items: center;
      column-gap: 179px;
    }
  }

.users {
  padding-left: 108px;
  padding-right: 108px;
  background: $second-background;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.header-users {
  display: flex;
  padding-bottom: 26px;
}
.infoGroup {
  display: flex;
  align-items: center;
  padding-left: 32px;
  p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: Inter-Regular, sans-serif;
    color: $text-secondary;
  }
}
.infoGroup p:nth-child(n+1) {
  padding-right:32px;
}
