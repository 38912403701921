@import "src/styles/common";

.data-table {
  &-header {
    background-color: $background-color;
    color: $header-background;
    border: none;
    border-bottom: 1px solid $border-color;

    th {
      font-family: Inter-Regular, serif;
      font-size: 14px;
      line-height: 20px;
      border: none;
    }
  }

  &-body {
    border: none;
  }

  &-row {
    font-family: Inter-Regular, serif;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 8px 7px;
    border: none;
    border-bottom: 1px solid $border-color;

    td {
      border: none;
    }
  }
}