@import "src/styles/common";

.tree-node {
  font-size: 14px;
  line-height: 18px;
  color: $text;
  cursor: pointer;
  margin-top: 8px;
  position: relative;
  min-width: fit-content;

  &-label {
    display: flex;
    align-items: center;

    .collapse-icon {
      width: 12px;
      height: 12px;
      margin-right: 6px;
      line-height: 18px;
      visibility: hidden;

      &__show {
        visibility: visible;
      }
    }

    &-name {
      margin-right: 10px;
      padding: 2px 4px;
      border-radius: 2px;
      transition: background-color 0.16s ease-in-out;
      white-space: nowrap;
      user-select: none;

      &__selected {
        background-color: #D6DBF3;
      }
    }

    &:hover {
      .tree-node-buttons {
        opacity: 1;
      }
    }
  }

  &-buttons {
    display: flex;
    opacity: 0;
    transition: opacity 0.16s ease-in-out;

    .icon {
      color: $main-color;
      margin-left: 12px;
    }
  }

  .line {
    position: absolute;
    width: 2px;
    background-color: $border;
    height: calc(100% - 18px);
    left: 5px;
    top: 22px;
  }

  &-more {
    color: #122EC2;
    font-size: 14px;
    line-height: 18px;
    font-family: Inter-Regular, serif;
    margin-left: 46px;
    margin-top: 8px;
    cursor: pointer;
  }

  &-add-item {
    margin-left: 24px;
    &__extra-margin {
      margin-left: 46px;
    }
  }

  .children {
    margin-left: 24px;
  }
}
