@import '../../styles/common.scss';

.container-desc {
display: flex;
justify-content: space-around;
padding-top: 48px;
padding-bottom: 48px;
max-width: 1224px;
margin: auto;
.desc {
  padding-right: 45px;
  width: 50%;
  .title-desc {
    font-family: Inter-Bold, sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    font-style: normal;
    margin: 0;
    white-space: pre-wrap;
  }
  .text {
    font-family: Inter-Regular, sans-serif;;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $text;
    white-space: pre-line;
    padding-top: 24px;

  }
  ul {
    padding-inline-start: 20px;
  }
  li {
    font-family: Inter-Regular, sans-serif;;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $text; 
  }
}
.video-box {
  flex: 1;
}
}